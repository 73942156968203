.img-spacer {
  height: 50vh;
}

.img-spacer-small {
  height: 40vh;
}

.about-me__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
