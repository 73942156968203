.section-header {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "Permanent Marker";
  font-size: 3.55rem;
  color: var(--color-primary-dark);
  animation: 1.5s ease-in 0s 1 slideInFromTop;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media all and (max-width: 429px) {
  .section-header {
    font-size: 2rem;
  }
}

@media all and (max-width: 321px) {
  .section-header {
    font-size: 1.8rem;
  }
}
