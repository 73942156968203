/* .form {
  width: 30rem;
  margin: 0 auto;
  padding: 1.5rem 0;
} */

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

form p {
  margin-bottom: 1.5rem;
}

*::-webkit-input-placeholder {
  color: #ababab;
}

*:-moz-placeholder {
  /* Firefox 18- */
  color: #ababab;
}

*::-moz-placeholder {
  /* Firefox 19+ */
  color: #ababab;
}

*:-ms-input-placeholder {
  /* IE10+ */
  color: #ababab;
}

input[type="text"],
input[type="email"],
textarea {
  width: 40rem;
  line-height: 1.5rem;
  font-family: inherit;
  border: none;
  color: var(--color-primary-dark);
  background: #ededed;
  overflow: auto;
  resize: none;
  padding-top: 0.75rem;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 0.75rem;
  font-family: "Varela Round";
}

input[type="text"],
input[type="email"] {
  height: 5rem;
  font-size: 3rem;
  line-height: 3rem;
}

textarea {
  height: 10rem;
  vertical-align: top;
  font-size: 3rem;
  padding: 2rem;
  line-height: 3rem;
  /* removes akward space directly below textarea */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  outline: 0;
  border: none;
}

input[type="submit"] {
  border: none;
  margin: 0;
  width: 100%;
  height: 5rem;
  text-align: center;
  font-family: inherit;
  line-height: 1.5rem;
  font-size: 3rem;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #ababab;
  -moz-transition: 1s;
  -o-transition: 1s;
  -webkit-transition: 1s;
  transition: 1s;
}
input[type="submit"]:hover {
  -moz-transition: 1s;
  -o-transition: 1s;
  -webkit-transition: 1s;
  transition: 1s;
  color: #fff;
  background: var(--color-primary-dark);
}
.pad-side {
  padding-left: 8.5rem;
  padding-right: 8.5rem;
}

@media all and (max-width: 700px) {
  input[type="text"],
  input[type="email"],
  textarea {
    width: 30rem;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}

@media all and (max-width: 600px) {
  input[type="text"],
  input[type="email"],
  textarea {
    width: 25rem;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}

@media all and (max-width: 429px) {
  .contact-title {
    font-size: 2rem;
  }
}

@media all and (max-width: 321px) {
  .contact-spacer--small {
    margin-bottom: 5rem;
  }
}
