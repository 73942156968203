:root {
  --color-primary: rgb(213, 213, 213);
  --color-primary-m: rgba(213, 213, 213, 0.8);
  --color-primary-dark: #434343;
  --color-primary-dark-m: rgba(67, 67, 67, 0.7);

  --color-secondary-lighter: #c0fff1;
  --color-secondary-light: rgb(202, 241, 233);
  --color-secondary: rgb(138, 221, 203);
  --color-secondary-dark: rgb(103, 216, 192);

  --color-tertiary: rgb(199, 182, 220);
  --color-tertiary-m: rgb(199, 182, 220, 0.8);

  /* --color-secondary: #edb1c4;
  --color-secondary-light: #f8e5eb;
  --color-secondary-dark: #db7093; */
  /*--color-primary-dark: rgb(21, 218, 182);
      --color-primary: #72e8d3;  */
}

@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px */
}

body {
  height: 100%;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder {
  height: 100vh;
}
