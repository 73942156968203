.landing-page {
  margin-top: 5rem;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-container {
  background-color: var(--color-secondary-light);
  height: 30rem;
  width: 30rem;
  border-radius: 50%;
  overflow: hidden;
}

.img-container img {
  height: 100%;
}
