.header__hamburger-btn-background {
  background-image: radial-gradient(rgb(202, 241, 233), rgb(138, 221, 203));
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  box-shadow: inset rgba(138, 221, 203, 0.9) 0 0 20px;
  z-index: 20;
  position: fixed;
  top: 5rem;
  right: 2rem;
}

.header__hamburger-btn {
  position: fixed;
}

.header__hamburger-icon,
.header__hamburger-icon-top,
.header__hamburger-icon-bottom {
  width: 3rem;
  height: 4px;
  border-radius: 25px;
  background-color: var(--color-primary-dark);
  display: inline-block;
  transition: all 0.2s;
  position: absolute;
  top: 3.2rem;
  left: 2rem;
}
.header__hamburger-icon-top {
  top: 2.2rem;
}

.header__hamburger-icon-bottom {
  top: 4.2rem;
}

/* .header__hamburger-btn:hover .header__hamburger-icon-top {
  top: 2rem;
}
.header__hamburger-btn:hover .header__hamburger-icon-bottom {
  top: 4.4rem;
} */

.header__hamburger-icon-top--active {
  transform: rotate(135deg);
  top: 3.2rem;
}

.header__hamburger-icon--active {
  opacity: 0;
}

.header__hamburger-icon-bottom--active {
  transform: rotate(-135deg);
  top: 3.2rem;
}

/* .header__nav-container {
  /* position: absolute; */

/* position: fixed;
  top: 0;
  left: 0;

  z-index: 1500; */
/* opacity: 0;
    width: 0;
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
/* } */

.header__nav-background {
  height: 7rem;
  width: 7rem;
  position: fixed;
  border-radius: 50%;
  top: 3rem;
  right: 3rem;
  background-image: radial-gradient(rgb(202, 241, 233), rgb(138, 221, 203));
  opacity: 0;
  z-index: 1;
  transition: all 0.2s linear;
}
.header__nav-list {
  width: 100%;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 10;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  font-family: "Permanent Marker", sans-serif;
  transition: all 0.3s linear;
  text-transform: uppercase;
}

.header__nav-background--open {
  transform: scale(195);
  opacity: 1;
}

.header__nav-list--open {
  left: 50%;
  visibility: visible;
  opacity: 1;
}

.header__nav-links {
  text-decoration: none;
  color: #434343;
  font-size: 4rem;
  text-decoration: none;
  transition: all 0.2s ease-in;
}

.header__nav-links:hover {
  color: var(--color-tertiary);
}

@media all and (max-width: 600px) {
}

@media all and (max-width: 443px) {
  .header__nav-links {
    font-size: 3.5rem;
  }
}

@media all and (max-width: 387px) {
  .header__nav-links {
    font-size: 3.2rem;
  }
}

@media all and (max-width: 357px) {
  .header__nav-links {
    font-size: 2.9rem;
  }
}

@media all and (max-width: 323px) {
  .header__nav-links {
    font-size: 2.7rem;
  }
}
