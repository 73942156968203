.blurb-container {
  position: relative;
  height: min-content;
  width: min-content;
  padding: 4rem 7rem;
  background-color: var(--color-tertiary);
  border-radius: 10px;
  overflow: hidden;
  /* border: 2px solid white; */
  box-shadow: -8px 9px 8px 1px rgba(0, 0, 0, 0.3);
}

.blurb-title {
  font-size: 3.5rem;
  font-family: "Permanent Marker";
  margin-bottom: 1rem;
  text-align: center;
}

.blurb-par {
  font-family: "Varela Round";
  text-indent: 5rem;
  width: 50rem;
  line-height: 3rem;
  font-size: 1.8rem;
}

.blurb-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blurb-link {
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  text-decoration: none;
  font-size: 2rem;
  font-family: "Permanent Marker";
  color: var(--color-primary-dark);
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.blurb-link:hover {
  color: var(--color-secondary-dark);
}

.blurb-margin {
  margin-bottom: 10rem;
}

.pad-bot {
  padding-bottom: 8rem;
}

.blurb-title--small {
  font-size: 2.8rem;
  text-align: center;
  width: 38rem;
}

@media all and (max-width: 700px) {
  .blurb-container {
    width: 52rem;
  }
  .blurb-title {
    font-size: 3.2rem;
  }
  /* .blurb-par {
    width: 40rem;
  } */
}

@media all and (max-width: 600px) {
  .blurb-container {
    width: 50rem;
  }

  .blurb-title {
    font-size: 3rem;
  }
  .blurb-par {
    width: 40rem;
  }
}

@media all and (max-width: 500px) {
  .blurb-container {
    width: 35rem;
  }
  .blurb-title {
    font-size: 2.8rem;
  }
  .blurb-par {
    width: 40rem;
  }
}

@media all and (max-width: 450px) {
  .blurb-container {
    width: 30rem;
  }

  .blurb-title {
    font-size: 2.5rem;
  }
  .blurb-par {
    width: 25rem;
  }
}
