.portfolio-img {
  transform: translateX(3rem);
}

.project-img__container {
  width: 75%;
  margin-top: 3rem;
}

.project-img__container img {
  width: 100%;
}

@media all and (max-width: 600px) {
  .project-img__container {
    width: 90%;
    margin-top: 1.5rem;
  }
}

@media all and (max-width: 429px) {
  .project-img__container {
    width: 90%;
    margin-top: 1.5rem;
  }
}
